export const config = {
    info: {
        version: "0. 1. 2 ALPHA",
        initScreen: "menu",
        menuScreen: "menu",
        review: {
            allow: false,
            endpointUrl: "https://adamhojer.cz/dod/rating.php",
            config: [
                {
                    urlAtr: "story",
                    index: 1,
                    cs: "Příběh",
                    en: "Story"
                },
                {
                    urlAtr: "quests",
                    index: 2,
                    cs: "Otázky a úkoly",
                    en: "Tasks and questions"
                },
                {
                    urlAtr: "it",
                    index: 3,
                    cs: "Technické zpracování",
                    en: "IT things (web)"
                },
                {
                    urlAtr: "game",
                    index: 4,
                    cs: "Celkový dojem",
                    en: "Whole game"
                }
            ]
        },
        authors: [
            {
                title: {
                    cs: "Program",
                    en: "Programming"
                },
                name: "Adam Hojer"
            },
            {
                title: {
                    cs: "Příběh, otázky a úkoly",
                    en: "Tasks & questions"
                },
                name: "Alina Marushchenko"
            },
            {
                title: {
                    cs: "Grafika",
                    en: "Design"
                },
                name: "Jakub Matas"
            }
        ]
    },
    colors: {
        default_color: "black",
        default_bg_img: "url('souhvezdyinfis.png')",
        overlay_color: "rgba(255, 255, 255, 0.75)"
    },
    border: {
        size: "2px",
        color: "black",
        radius: "0.5rem",
    }
}

interface Rating {
    from: number,
    to: number,
    text: string
}

interface Game {
    name: string,
    icon: string,
    content: number[],
    bg: string,
    rating: Rating[]
}

type Games = {
    [key: number]: Game
}

export const games:Games = {
    1: {
        name: "Logistika",
        icon: "logistics.svg",
        content: [],
        bg: "",
        rating: [
            {
                from: 0,
                to: 0,
                text: "O finanční gramotnosti jsi asi ještě nikdy neslyšel/a. Nevadí. Na INFISu se dozvíš všechno potřebné."
            },
            {
                from: 1,
                to: 1,
                text: "O finanční gramotnosti jsi už někdy slyšel/a, ale zatím Tě nikdo o její důležitosti nepřesvědčil. Na INFISu pochopíš, proč je finanční gramotnost důležitá."
            },
            {
                from: 2,
                to: 2,
                text: "Na velikou oslavu to není, ale nezoufej, na INFISu vše doženeš."
            },
            {
                from: 3,
                to: 3,
                text: "Tvůj výsledek není špatný, ale ještě by to chtělo na tom zapracovat. INFIS je správná volba!"
            },
            {
                from: 4,
                to: 4,
                text: "Není to špatný výsledek, ale ještě by to chtělo pár znalostí si doplnit. S INFISem to půjde snadno."
            },
            {
                from: 5,
                to: 5,
                text: "Blahopřeji! Skvělý výsledek. Chceš se o finanční gramotnosti dozvědět víc? Pojď studovat INFIS."
            }
        ]
    }
}
/*
export var set = {
    default: [1, 9696]
}
*/
/*
export const map = [
    450, 451, 452, 453, 454, 455
]
*/

/*export const map = [
    201, 202, 5128,
    203, 204, 8964,
    205, 7433,
    206, 207, 8465,
    208, 209, 2103, 
    211, 212, 213, 214, 9452,
    215, 216, 9472,
    217, 218, 219, 9543,
    220, 9559,
    221, 3648,
    222, 223, 224, 9546,
    225, 226, 227, 228, 8730,
    229, 230, 231, 1284,
    232, 233, 234, 235, 4086,
    236, 237, 238, 239, 9403,
    240, 241, 4107,
    242, 243, 244, 248, 2016,
    245, 246, 247, 6969
]*/

export const maps = {
    14:    
        [
            /*1000, 1001, 1002, 2002, */
            
            8001, 6969

            /*1003, 1004, 1005, 2005, */
            
            //8002

           /* 1006, 1007, 1008, 1009, 1010, 2010, 8003,
        
            1011, 1012, 1013, 6969*/
        ],

    15:
        [

            9002, 6969
            //4000, 4001, 4002, 4003, 4004, 4005, 5005, 9001,

            //4006, 4007, 4008, 9002, 
            
            //4009, 4010, 4011, 4012, 
        ]
}

interface Question {
    text: string,
    answers: string[],
    correct: number
}

export var data = {

    /*
        !! DO type ATRIBUTU SE PROZATIM DOPLNUJI STRINGY, NAHRAZENI PLNOHODNOTNYMI OBJEKTY SE ZVAZUJE DO BUDOUCNA !!
        Pozn. pro Ad.: vyřešila by se potřeba definovat enums + nemusel už by být ActualModule,
        z type atributu by se rovnou načítalo..
        !! PROBLÉM: předávání parametrů, co ještě nejsou řešeny globálním stavem =>
            a) převést na globální stav
            b) předávat v nadřazené komponentě a toto použít jako podkomponentu
            c) ..
    */

            
/*
//úvod
    201: {
        type: "story",
        noQrCode: true,
        res: {
        text: "Nacházíte se ve vzducholodi vysoko nad pohořím."
        }
    },
    
    202: {
        type: "story",
        noQrCode: true,
        res: {
            text: "Když tu vás náhle vyzval neznámý cizinec na souboj v pexesu!!"
        }
    },


    5128:{
        type: "pexeso", 
        res: {
            pairs: [ 
                {img: "instagram.png",pair: 1,}, {text: "Instagram",pair: 1,},
                {img: "discord.png",pair: 2,}, {text: "Discord",pair: 2,},
                {img: "facebook-messenger.png",pair: 3,}, {text: "Messenger",pair: 3,},
                {img: "appleBrand.png",pair: 4,}, {text: "Apple",pair: 4,},
                {img: "microsoft.png",pair: 5,}, {text: "Windows",pair: 5,},
                {img: "whatsapp.png",pair: 6,}, {text: "WhatsApp",pair: 6,},
                {img: "reddit.png",pair: 7,}, {text: "Reddit",pair: 7,},
                {img: "github.png",pair: 8,}, {text: "GitHub",pair: 8,}
            ]
        }
    },

//první děj (pád)

    203: {
        type: "story",
        noQrCode: true,
        res: {
            text: "Poté, co jste dohráli, tak cítíte jemné záchěvy celé konstrukci."
        }
    },

    204: {
        type: "story",
        noQrCode: true,
        res: {
            text: "Když tu vám náhle kapitán oznámí, že se pod vámi něco děje... "
        }
    },



    8964:{
        type: "question",
        res: {
            cs: {
                text: "Vzducholoď se otřásá... Sirény začaly houkat poplach... Co se to děje!!",
                answers: [
                    "Trefili nás mimozemšťani?",
                    "Padáme k zemi!!",
                    "Vstoupili jsme do stratosféry?!",
                    "Planý poplach. Vždyť vzducholoď je NEZNIČITELNÁ."
                ]
            },
            right: [1]
        }
    },

    
    205: {
        type: "story",
        noQrCode: true,
        res: {
            text: "Vidíte jak kolem oken létají kousky vzducholodě."
        }
    },


    7433: {
        type: "question",
        res: {
            cs: {
                text: "Po velkém otřesu, který vás všechny smetl na zem, se stále snažíte přijít na to, co se stalo.",
                answers: [
                    "Narazili jsme do země.",
                    "Přistáli jsme na pirátské lodi.",
                    "Probudili jsme se z noční můry."
                ]
            },
            right: [0] 
        }
    },

//průzkum - 1

    206: {
        type: "story",
        noQrCode: true,
        res: {
            text: "Nejlepší nápad je vydávat se na průzkum po zničené gondole."
        }
    },

    207: { 
        type: "story",
        noQrCode: true,
        res: {
            text: "První místnost, která vypadá v dobrém stavu, je místnost s velkým nápisem NEPOŘÁDEK"
        }
    },

    8465: {
        type: "question",
        res: {
            cs: {
                text: "Chtelo by to vzít něco co by se nám hodilo na prozkoumávání věcí",
                answers: [
                    "Baterka",
                    "Dlouhá nepotřebná tyč",
                    "Malý tupý kapesní nožík",
                    "Burákový máslo",
                    "Strom"
                ]
            },
            right: [0] 
        }
    },

    208: { 
        type: "story",
        noQrCode: true,
        res: {
            text: "To by se mohlo hodit, tak si budem pamatovat, že to tu je."
        }
    },

    209: { 
        type: "story",
        noQrCode: true,
        res: {
            text: "Naštěstí jste si všimli i malé blyštivé věcičky vzádu v místnosti."
        }
    },

    2103: {
        type: "question",
        res: {
            cs: {
                text: "Je to pták? Je to letadlo? Né je to:",
                answers: [
                    "malá bomba!!",
                    "přístupová karta",
                    "bezcenná cetka"
                ]
            },
            right: [1] 
        }
    },

    211: {
        type: "story",
        noQrCode: true,
        res: {
            text: "Když ji zvednete, tak vidíte napsané malé číslo. 59641, to by se mohlo hodit"
        }
    },

    212: {
        type: "story",
        noQrCode: true,
        res: {
            text: "Vzhledem k tomu, že máte ještě špatnou paměť po nárazu, tak si ho chytře zapisujete, aby jste ho nezapomněli."
        }
    },

    213: {
        type: "story",
        noQrCode: true,
        res: {
            text: "Pak už vás nic jiného nezaujalo, a tak jdete do další místnosti s nápisem TECH"
        }
    },
//průzkum - 2
    214: {
        type: "story",
        noQrCode: true,
        res: {
            text: "První, co vás zaujalo, byla krabice s nářadím"
        }
    },

    9452: { //nářadí
        type: "fields",
        res: {
            cs: {
                pairs: [
                    {img: "screwdriver.png", answer: "šroubovák"},
                    {img: "nail.png", answer: "hřebíky"},
                    {img: "hammer.png", answer: "kladivo"},
                    {img: "screws.png", answer: "šrouby"},
                    {img: "crowbar.png", answer: "páčidlo"},
                    {img: "tape.png", answer: "tesa"},
                    {img: "scissors.png", answer: "nůžky"},
                    {img: "pliers.png", answer: "kleště"},
                ],
                extraOptions: ["mandarinka", "kapesní nožík"]
            },
            oneuse: true 
        }
    },

    215: {
        type: "story",
        noQrCode: true,
        res: {
            text: "Poté, co jste si prohlédli všechno užitečné nářadí, tak jste si všimli pohybu vpředu v místnosti"
        }
    },
    
    216: {
        type: "story",
        noQrCode: true,
        res: {
            text: "Promluvil na vás starý ošuntělý r\obot: Jáá á by bY bYCh poTřEbOVat PomOCt, jÁ neMit.."
        }
    },

    9472: {
        type: "task",
        res: {
            cs: {
                text: "Co by robot mohl potřebovat, když to vypadá, že má všechny součástky, aby na dále fungoval?",
                hint: "generuje to generátor",
                input: "text",
                answers: ["energii", "elektřinu"]
            }
        }
    },

//jídelna
    
    217: {
        type: "story",
        noQrCode: true,
        res: {
            text: "Uf, to je vyčerpávající prohledávat všechny ty místnosti!"
        }
    },

    218: {
        type: "story",
        noQrCode: true,
        res: {
            text: "Chtělo by too něco k zakousnutí. Když nás uváděli na vzducholoď, tak nám říkali něco o jídelně."
        }
    },

    219: {
        type: "story",
        noQrCode: true,
        res: {
            text: "Nebylo to někde u vestibulu a nějakou dlouhou chodbou až ke stolu??"
        }
    },

    9543: {
        type: "pexeso",
        res: {
            pairs: [
                {img: "water-bottle.png",pair: 1,}, {text: "Voda",pair: 1,},
                {img: "cola.png",pair: 2,}, {text: "CokaCola",pair: 2,},
                {img: "pepsi.png",pair: 3,}, {text: "Pepsi",pair: 3,},
                {img: "energy-drink.png",pair: 4,}, {text: "Energy Drink",pair: 4,},
                {img: "orange-juice.png",pair: 5,}, {text: "Džus",pair: 5,},
                {img: "beer.png",pair: 6,}, {text: "Pivo",pair: 6,},
                {img: "neznámáTekutina.png",pair: 7,}, {text: "Neznámá tekutina",pair: 7,},
            ]
        }
    },

    220: {
        type: "story",
        noQrCode: true,
        res: {
            text: "O žízeň úspěšně postaráno. Teď by to chtělo něco k jídlu."
        }
    },

    9559: {
        type: "fields",
        res: {
            cs: {
                pairs: [
                    {img: "chlebaSMaslem.png", answer: "chleba s máslem"},
                    {img: "rizekVChlebu.png", answer: "řízek v chlebu"},
                    {img: "apple.png", answer: "jablko"},
                    {img: "trubicky.png", answer: "hořičské trubičky"},
                ],
                extraOptions: ["drobky pro holuby", "houbičky", "burgír", "knedlo-zelo-vepřo"]
            },
            oneuse: true
        }
    },

    221: {
        type: "story",
        noQrCode: true,
        res: {
            text: "Skvělá toť svačinka, teď už jenom: co si vezmeme na cestu?"
        }
    },

    3648: {
        type: "question",
        res: {
            cs: {
                text: "Chtělo by to něco hodně nutričního. Takže co si vzít?",
                answers: [
                    "párek v rohlíku a malinovka",
                    "hnusný kousek papíru co leží v rohu místnosti",
                    "neexistující talíř plný hovězího"
                ]
            },
            right: [0] 
        }
    },

//průzkum - 3

    222: {
        type: "story",
        noQrCode: true,
        res: {
            text: "Čas prozkoumávat další místnosti!"
        }
    },
    
    223: {
        type: "story",
        noQrCode: true,
        res: {
            text: "Je na čase jít do IT místnosti!"
        }
    },

    224: {
        type: "story",
        noQrCode: true,
        res: {
            text: "To je ale obří ovládací panel! Ale vypadá zanešeně, chtělo by ho uklidit!"
        }
    },

    9546: {
        type: "task",
        res: {
            cs: {
                text: "Hmm, ono to po nás chce nějaký heslo. To tu někde musí být!",
                hint: "Ukliď plochu",
                input: "text",
                answers: ["ali", "poli"]
            }
        }
    },

    225: {
        type: "story",
        noQrCode: true,
        res: {
            text: "Pořád nechápu, kdo si dává jako heslo jméno nejoblíbenější osoby :)"
        }
    },

    226: {
        type: "story",
        noQrCode: true,
        res: {
            text: "Ale vlastně mi to nevadí! Ono je tu napsáno, že tu je záchranný modul??"
        }
    },

    227: {
        type: "story",
        noQrCode: true,
        res: {
            text: "A hele! Ono se vzadu opět něco nachází, ještě že to kontrolujeme!"
        }
    },

    228: {
        type: "story",
        noQrCode: true,
        res: {
            text: "Tady leží počítač od záchrannýho modulu, jenom škoda, že je rozebraný a nevíme, co je co."
        }
    },

    8730: {
        type: "fields",
        res: {
            cs: {
                pairs: [
                    {img: "procesor.png", answer: "Procesor"},
                    {img: "RAM.png", answer: "RAM"},
                    {img: "ssd.png", answer: "SSD"},
                    {img: "case.png", answer: "Case"},
                    {img: "zdroj.png", answer: "Zdroj"},
                    {img: "grafika.png", answer: "Grafika"},
                ],
                extraOptions: ["zásuvka", ""]
            },
            oneuse: true
        }
    },

//průzkum - 4

    229: {
        type: "story",
        noQrCode: true,
        res: {
            text: "Tak! Teď by mě zajímalo, co bude v další místnosti.."
        }
    },

    230: {
        type: "story",
        noQrCode: true,
        res: {
            text: "Proč se to jmenuje HVĚZDÁRNA?"
        }
    },

    231: {
        type: "story",
        noQrCode: true,
        res: {
            text: "Hele mapa, konečně můžeme zjistt kde jsme."
        }
    },

    1284: {
        type: "task",
        res: {
            cs: {
                text: "Kde že to jsme?",
                hint: "Je to v Čechách",
                input: "text",
                answers: ["na sněžce", "sněžka", "snezka", "sněžka"]
            }
        }
    },

    232: {
        type: "story",
        noQrCode: true,
        res: {
            text: "Hele, my jsme v Čechách! Tak to nejsme tak daleko."
        }
    },

    233: {
        type: "story",
        noQrCode: true,
        res: {
            text: "Teď už je to jenom kousek do cíle."
        }
    },

    234: {
        type: "story",
        noQrCode: true,
        res: {
            text: "Už musíme jen následovat souhvězdí, které je přímo nad tím."
        }
    },

    235: {
        type: "story",
        noQrCode: true,
        res: {
            text: "Ještěže vzádu v místnosti je dalekohled!"
        }
    },

    4086: {
        type: "pexeso",
        res: {
            pairs: [
                {img: "cassiopea.png",pair: 1,}, {text: "Cassiopea",pair: 1,},
                {img: "velkyVuz.png",pair: 2,}, {text: "Velký vůz",pair: 2,},
                {img: "souhvezdyinfis.png",pair: 3,}, {text: "INFIS",pair: 3,},
            ]
        }
    },

//průzkum - 5

    236: {
        type: "story",
        noQrCode: true,
        res: {
            text: "No, tak teď víme kam letět a dokonce máme věci na opravu! Už nám chybí jenom všechno ostatní.."
        }
    },

    237: {
        type: "story",
        noQrCode: true,
        res: {
            text: "Tak honem do předposlední místnosti."
        }
    },

    238: {
        type: "story",
        noQrCode: true,
        res: {
            text: "Ty jo, to je fakt pojmenování. DŮLEŽITÁ MÍSTNOST. Kdo tohle vymýšlel??"
        }
    },

    239: {
        type: "story",
        noQrCode: true,
        res: {
            text: "Tu je kapitán, ale je těžce zraněný, naštěstí ale má nanity, ty ho zachrání."
        }
    },

    9403: {
        type: "question",
        res: {
            cs: {
                text: "Co s ním ale teď?",
                answers: [
                    "Dělat jako že neexistuje a jít dál",
                    "Nechat mu kousek svačiny",
                    "hodit po něm kousek dřeva a probudit ho"
                ]
            },
            right: [1] 
        }
    },

    240: {
        type: "story",
        noQrCode: true,
        res: {
            text: "když jste se k němu přiblížili, tak se pohnul a velmi potichu vám řekl:"
        }
    },

    241: {
        type: "story",
        noQrCode: true,
        res: {
            text: "Až odletíte, pošlete pro nás záchranu? prosíí..."
        }
    },

    4107: {
        type: "question",
        res: {
            cs: {
                text: "Zachránit, či nezachránit, toť oč tu běží.",
                answers: [
                    "Zachránit",
                    "Nezachránit"
                ]
            },
            right: [0] 
        }
    },

//průzkum - 6

    242: {
        type: "story",
        noQrCode: true,
        res: {
            text: "No tak teď už zbývá jen jedna místnost. To je ta, kterou chceme celou dobu!"
        }
    },

    243: {
        type: "story",
        noQrCode: true,
        res: {
            text: "Je načase..."
        }
    },

    244: {
        type: "story",
        noQrCode: true,
        res: {
            text: "Po otevření dveří vidíte malý horkovzdušný balon s místy akorát pro vás všechny. Stačí ho jen opravit."
        }
    },

    248: {
        type: "story",
        noQrCode: true,
        res: {
            text: "Ještě než odletíme tak by si chtělo zapamatovat našeho hrdinu."
        }
    },

    2016: {
        type: "question",
        res: {
            cs: {
                text: "Jak se jmenuje ředitel téhle vzducholodi?",
                answers: [
                    "Šmídl",
                    "Chlopčík",
                    "Matas",
                    "Hojer"
                ]
            },
            right: [] 
        }
    },

//konec

    245: {
        type: "story",
        noQrCode: true,
        res: {
            text: "Vzhůru ke hvězdám! Do nekonečna a ještě dál!"
        }
    },

    246: {
        type: "story",
        noQrCode: true,
        res: {
            text: "A teď už letíme na nejlepší místo světa."
        }
    },

    247: {
        type: "story",
        noQrCode: true,
        res: {
            text: "Hurá na INFIS!"
        }
    }, */
    
 /*   1000: {

        type: 'story',
        noQrCode: true,
        res: {
            text: 'Honza seděl v zadní lavici třídy a upíral oči na hodiny. Před ním učitelka monotónně přednášela o počítačových komponentech, které Honza očividně uměl složit i se zavřenýma očima.',
            img: ""
        }

    },

    1001: {

        type: 'story',
        noQrCode: true,
        res: {
            text: 'Honza se snažil zůstat vzhůru. Jeho myšlenky však neustále odbíhaly k jediné věci – k ledově vychlazené plechovce energetického nápoje, který ho čekal v automatu na chodbě.',
            img: ""
        }

    },



    1002: {

        type: 'story',
        noQrCode: true,
        res: {
            text: '„Potřebuju ten energeťák, jinak usnu,“ pomyslel si a zoufale se podíval na hodiny. Ještě deset minut. Jen deset minut a bude volný.',
            img: ""
        }

    },


2002: {

        type: 'story',
        noQrCode: true,
        res: {
            text: 'Aby hodina proběhla co nejrychleji, přiřaď s Honzíkem názvy k správným PC komponentám:',
            img: ""
        }

    }, */


 8001: {
        type: "fields",
        res: {
            cs: {
                pairs: [
                    {img: "procesor.png", answer: "Procesor"},
                    {img: "RAM.png", answer: "RAM"},
                    {img: "ssd.png", answer: "SSD"},
                    {img: "case.png", answer: "Case"},
                    {img: "zdroj.png", answer: "Zdroj"},
                    {img: "grafika.png", answer: "Grafika"},
                ],
                extraOptions: []
            },
            oneuse: true
        }
    },


 /*   1003: {

        type: 'story',
        noQrCode: true,
        res: {
            text: 'Zazvonil zvonek. Honza okamžitě vyskočil ze židle a bez ohlédnutí se rozběhl ke dveřím.',
            img: ""
        }

    },


    1004: {

        type: 'story',
        noQrCode: true,
        res: {
            text: 'Ve chvíli, kdy se dostal na chodbu, už v hlavě přesně věděl, kde automat stojí. Proplétal se mezi ostatními studenty a konečně spatřil svůj cíl.',
            img: ""
        }

    },


    1005: {

        type: 'story',
        noQrCode: true,
        res: {
            text: 'Honza doběhl k automatu a začal očima rychle hledat svůj oblíbený energetický nápoj. Automat byl plný různých plechovek a lahví, ale Honza měl vycvičené oko - přesně věděl co hledá!',
            img: ""
        }

    },

2005: {

        type: 'story',
        noQrCode: true,
        res: {
            text: 'Za co nejméně tahu, zkus najit všechny páry nápojů v automatu:',
            img: ""
        }

    },

    

  8002: {
        type: "pexeso",
        res: {
            pairs: [
                {img: "water-bottle.png",pair: 1,}, {text: "Voda",pair: 1,},
                {img: "cola.png",pair: 2,}, {text: "CokaCola",pair: 2,},
                {img: "pepsi.png",pair: 3,}, {text: "Pepsi",pair: 3,},
                {img: "energy-drink.png",pair: 4,}, {text: "Energy Drink",pair: 4,},
                {img: "orange-juice.png",pair: 5,}, {text: "Džus",pair: 5,},
                {img: "beer.png",pair: 6,}, {text: "Pivo",pair: 6,},
                {img: "neznámáTekutina.png",pair: 7,}, {text: "Neznámá tekutina",pair: 7,},
            ]
        }
    },



    1006: {

        type: 'story',
        noQrCode: true,
        res: {
            text: '„Kde to je?“ pomyslel si netrpělivě, když v tom ho uviděl – jasně, zelená plechovka s černým nápisem. To bylo ono!',
            img: ""
        } 

    }, 


    
    1007: {

        type: 'story',
        noQrCode: true,
        res: {
            text: 'Honza začal prohrabávat kapsy s myšlenkou, že někde najde ještě nějaké mince, ale marně. Když chlap nic nenašel, rychle začal něco vymyšlet.',
            img: ""
        }

    },


    1008: {

        type: 'story',
        noQrCode: true,
        res: {
            text: 'Rozhlédl se po chodbě a spatřil svou kamarádku Pavlu, která se opírala o zeď a bavila se s dalšími spolužáky. „Pavlo!“ zavolal na ni. „Potřebuju půjčit pár drobných na energeťák, máš nějaké? Vrátím ti to zítra, slibuju.“',
            img: ""
        }

    },

    1009: {

        type: 'story',
        noQrCode: true,
        res: {
            text: 'Pavla se podívala na Honzu s pobaveným úsměvem. „A co z toho budu mít já?“ zeptala se. „Třeba nějakou protislužbu?“ Honza zoufale přemýšlel. „No, co bys potřebovala?“',
            img: ""
        }

    },

    1010: {

        type: 'story',
        noQrCode: true,
        res: {
            text: 'Pavla se zamyslela. „Mám problém s jednou úlohou z matematiky. Pomůžeš mi s ní? Pak ti dám peníze.“ Honzík s vidinou peněz se rychle začal dívat na úkol.',
            img: ""
        }

    },


  2010: {

        type: 'story',
        noQrCode: true,
        res: {
            text: 'Vyřeš s Honzou přiklad:',
            img: ""
        }

    },


8003: {
        type: "task",
        res: {
            cs: {
                text: "Ze Strážnice vyjela po Baťově kanálu motorová loď rychlostí 12 km/h směrem ke kotvišti u Otrokovic. Kolik kilometrů je to ze Strážnice do Otrokovic, když loď tam připlula za dvě hodiny?",
                hint: "12*2...",
                input: "text",
                answers: ["24", "24 km", "24km"]
            }
        }
    },


    1011: {

        type: 'story',
        noQrCode: true,
        res: {
            text: 'Pavla vytáhla z kapsy pár mincí a podala je Honzovi. „Tady máš, jak jsem slíbila. A díky za pomoc.“',
            img: ""
        }

    },


    1012: {

        type: 'story',
        noQrCode: true,
        res: {
            text: 'Honza si vzal mince s úlevou a rychle se vrátil k automatu. Vhodil mince dovnitř, stiskl tlačítko a sledoval, jak plechovka spadla do výdejní části.',
            img: ""
        }

    },

    1013: {

        type: 'story',
        noQrCode: true,
        res: {
            text: 'S vítězným úsměvem ji vytahl, otevřel a konečně se napil. Chladivá, sladká tekutina mu osvěžila ústa a cítil, jak se mu okamžitě vrací energie.',
            img: ""
        }

    },

    */
    



    6969: {
        type: "end",
        noQrCode: true,
        res: {

        }
    },
/*

    4000: {

        type: 'story',
        noQrCode: true,
        res: {
            text: 'Jednoho krásného dne se Krteček rozhodl, že uspořádá překvapení pro své kamarády.',
            img: "krtek.png",
        }

    },

    4001: {

        type: 'story',
        noQrCode: true,
        res: {
            text: 'Chtěl jim připravit večírek plný radosti a smíchu, a tak se pustil do plánování.',
            img: "krtek.png",
        }

    },



    4002: {

        type: 'story',
        noQrCode: true,
        res: {
            text: 'Nejprve se vydal na trh do města. Věděl, že tam najde všechno potřebné.',
            img: "krtek.png",
        }

    },


4003: {

        type: 'story',
        noQrCode: true,
        res: {
            text: 'Když dorazil na trh, začal vybírat věci k večírku a skládat je do svého autíčka.',
            img: "krtek.png",
        }

    },




    4004: {

        type: 'story',
        noQrCode: true,
        res: {
            text: 'Krteček koupil šťavnaté jahody, sladké hrozny, čerstvé mrkve a chutné salátové listy. Pak zamířil k pekárně, kde koupil čerstvě upečený chléb a voňavé koláče.',
            img: "krtek.png",
        }

    },


    4005: {

        type: 'story',
        noQrCode: true,
        res: {
            text: 'Poté Krteček navštívil malý obchod s delikatesami, kde vybral nejlepší sýry a oříšky. Nezapomněl ani na nápoje, a tak vzal džbánek čerstvě vymačkané ovocné šťávy.',
            img: "krtek.png",
        }

    },


    5005: {

        type: 'story',
        noQrCode: true,
        res: {
            text: 'S Krtečkem poskládejte zboží do správné přepravky:',
            img: ""
        }

    },

 9001: {
        type: "fields",
        res: {
            cs: {
                pairs: [
                    {img: "apple.png", answer: "zelená"},
                    {img: "muffin.png", answer: "bílá"},
                    {img: "meat.png", answer: "červená"},
                    {img: "carrot.png", answer: "černá"},
                    {img: "neznámáTekutina.png", answer: "přepravka se zeleným křižem"},
                ],
            },
            oneuse: true
        }
    },



4006: {

        type: 'story',
        noQrCode: true,
        res: {
            text: 'Když měl všechno nakoupené, vrátil se zpět do lesa a začal s přípravami. Vybral krásné místo pod velkým dubem, kde rozložil deky a připravil stoly.',
            img: "krtek.png",
        }

    },



    4007: {

        type: 'story',
        noQrCode: true,
        res: {
            text: 'Na stoly naaranžoval květiny a připravil talíře s jídlem. Všechno muselo být dokonalé.',
            img: "krtek.png",
        } 

    }, 


    
    4008: {

        type: 'story',
        noQrCode: true,
        res: {
            text: 'Zahrajte pexeso a tím pomůžete Krtečkovi připravit jídlo:',
            img: ""
        }

    },

    */

9002: {
        type: "pexeso",
        res: {
            pairs: [
                {img: "water-bottle.png",pair: 1,}, {text: "Voda",pair: 1,},
                {img: "rizekVChlebu.png",pair: 2,}, {text: "Burger",pair: 2,},
                {img: "chlebaSMaslem.png",pair: 3,}, {text: "Chleba s maslem",pair: 3,},
                {img: "salad.png",pair: 4,}, {text: "Ovocný salát",pair: 4,},
                {img: "orange-juice.png",pair: 5,}, {text: "Džus",pair: 5,},
                {img: "spaggetty.png",pair: 6,}, {text: "Těstoviny",pair: 6,}
            ]
        }
    },

/*


    4009: {

        type: 'story',
        noQrCode: true,
        res: {
            text: 'Když bylo všechno hotovo, poslal vzkazy svým kamarádům. "Přijďte dnes večer pod velký dub. Čeká vás překvapení!" stálo v každém z nich.',
            img: "krtek.png",
        }

    },

    4010: {

        type: 'story',
        noQrCode: true,
        res: {
            text: 'Večer se zvířátka začala scházet. Myška, Zajíc, Ježek a další kamarádi dorazili plní očekávání. Když uviděli připravený večírek, nemohli uvěřit svým očím.',
            img: ""
        }

    },

    4011: {

        type: 'story',
        noQrCode: true,
        res: {
            text: 'Krteček byl strašně rád že zvládl potěšit své kamarády.',
            img: ""
        }

    },


  4012: {

        type: 'story',
        noQrCode: true,
        res: {
            text: 'Děkujeme za hraní, zachránili jste večírek Krtečku!',
            img: ""
        }

    },
*/

//předpisy !!!!

    800000: {
        type: "story",
        noQrCode: true,
        res: {
            text: ""
        }
    },

    800001: {
        type: "pexeso",
        res: {
            pairs: [
                {img: "",pair: 1,}, {text: "",pair: 1,},
            ]
        }
    },

    800002: {
        type: "task",
        res: {
            cs: {
                text: "",
                hint: "",
                input: "",
                answers: ["", ""]
            }
        }
    },

    800003: {
        type: "question",
        res: {
            cs: {
                text: "",
                answers: [
                    "",
                    "",
                    ""
                ]
            },
            right: [] 
        }
    },

    800004: {
        type: "fields",
        res: {
            cs: {
                pairs: [
                    {img: "", answer: ""},
                    {text: "", answer: ""},
                ],
                extraOptions: [""]
            },
            oneuse: true //Každá z možností může být využita max. jednou => po použití už nebude v nabídce
        }
    },
    
}
